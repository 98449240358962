import { Select } from "antd";
import { UploadFiles } from "components/Upload/UploadFile";
import { DataType } from "types/challenge";
import { StatTypeChildProps } from "../StatType";
import "../updateStats.scoped.scss";

export const DropdownType: React.FC<StatTypeChildProps> = ({
  data,
  userResult,
  setUserResult,
  week,
  onUpdateChild,
  order,
  disabled,
  files,
  onRemoveFile,
  setLoading,
  loading,
  callback,
}) => {
  const items = data.results[DataType.Dropdown]?.map((item: any, index: number) => {
    return {
      value: item.point + index + item.answer,
      label: item.answer,
      point: item.point,
    };
  });
  const onUpdate = async (value: number, user_answer: number) => {
    const _child = onUpdateChild?.(value, user_answer, data);
    const _userResult = {
      ...userResult,
      [week]: _child,
    };
    setUserResult(_userResult);
  };
  const user_answer = userResult?.[week]?.[order]?.user_answer;
  const value = items.find((item: any) => item.label === user_answer)?.value;
  return (
    <div className="single-activity">
      <hr className="single-activity-hr" />
      <div className="j-between single-activity-group ">
        <div className="show-on-responsive one">
          <div className="left-col col">{data.title}</div>
          <div className="center-col col">{data.description}</div>
        </div>
        <div className="left-col col show-on-lg">{data.title}</div>
        <div className="center-col col show-on-lg">{data.description}</div>
        <div className="right-col j-between col two">
          <Select
            placeholder="Select"
            allowClear
            style={{ width: "100%" }}
            options={items || []}
            value={value}
            onChange={(_value, ops) => {
              const opt = ops as any;
              onUpdate(opt.point, opt.label);
            }}
            disabled={disabled}
          />
        </div>
        <div className="right-right-col col upload-col three">
          <UploadFiles
            key={files[files.length - 1]}
            isDisabled={disabled}
            loading={loading}
            setLoading={setLoading}
            callback={callback}
            onRemoveFile={onRemoveFile}
            files={files}
          />
        </div>
      </div>
    </div>
  );
};
